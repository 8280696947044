// .wrap {
//     background: #090f35;
//     padding: 10px 0;
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     z-index: 2;

//     .container {}
// }

// img {
    // height: 150px;
    // background-position: center;
    // /* Make the background image cover the area of the <div>, and clip the excess */
    // background-size: cover;
    // object-fit: contain;
    // width: 145px;
    // height: 33px;
// }


// @media (max-width: 991.98px) {
//     .wrap {
//         display: none;
//     }
// }

// .wrap .phone {
//     font-size: 12px;
//     color: rgba(255, 255, 255, 0.8);
// }

// .wrap .phone a {
//     color: white;
//     font-size: 12px;
// }

// .wrap .phone .fa {
//     color: rgba(255, 255, 255, 0.8);
// }

// .wrap .phone .mailus {
//     color: rgba(255, 255, 255, 0.6);
// }


// .ftco-navbar-light {
//     background: transparent !important;
//     position: absolute;
//     top: 60px;
//     left: 0;
//     right: 0;
//     z-index: 3;

//     .container {
//         width: 1110px;
//         margin: 0 auto;

//         .navbar-brand {
//             color: #323edd;
//             padding-top: 1.5rem;
//             padding-bottom: 1.5rem;

//             span {
//                 color: #090f35;
//             }
//         }

//         .navbar-brand:hover {
//             color: #323edd;
//         }
//     }

//     .navbar-nav>.nav-item {
//         .nav-link {
//             font-size: 15px;
//             padding-top: 1.5rem;
//             padding-bottom: 1.5rem;
//             padding-left: 20px;
//             padding-right: 20px;
//             color: #000000;
//             font-weight: 400;
//             opacity: 1 !important;
//         }

//         .nav-link:hover,
//         .nav-link:focus {
//             color: #000000;
//         }

//         .dropdown-menu {
//             border: none;
//             background: #fff;
//             -webkit-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
//             -moz-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
//             box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
//         }

//         .ftco-seperator {
//             position: relative;
//             margin-left: 20px;
//             padding-left: 20px;
//         }

//         .ftco-seperator:before {
//             position: absolute;
//             content: "";
//             top: 10px;
//             bottom: 10px;
//             left: 0;
//             width: 2px;
//             background: rgba(255, 255, 255, 0.05);
//         }
//     }

//     .navbar-nav>.nav-item.cta>a {
//         color: #fff;
//         border: 1px solid #323edd;
//         padding-top: .8rem;
//         padding-bottom: .8rem;
//         padding-left: 18px;
//         padding-right: 18px;
//         background: #323edd;
//         margin-top: .5rem;
//         -webkit-border-radius: 2px;
//         -moz-border-radius: 2px;
//         -ms-border-radius: 2px;
//         border-radius: 2px;
//     }

//     .navbar-nav>.nav-item.cta>a span {
//         display: inline-block;
//         color: #fff;
//     }

//     .navbar-nav>.nav-item.cta>a:hover {
//         background: #323edd;
//         border: 1px solid #323edd;
//     }

//     .navbar-nav>.nav-item.cta.cta-colored a {
//         border: 1px solid #ffa41b;
//         background: #ffa41b !important;
//     }

//     .navbar-nav>.nav-item.active>a {
//         color: #323edd;
//     }

//     .navbar-toggler {
//         border: none;
//         color: rgba(255, 255, 255, 0.5) !important;
//         cursor: pointer;
//         padding-right: 0;
//         text-transform: uppercase;
//         font-size: 16px;
//         letter-spacing: .1em;
//     }

//     .navbar-toggler:hover,
//     .navbar-toggler:focus {
//         text-decoration: none;
//         color: #323edd;
//         outline: none !important;
//     }
// }

// @media (max-width: 991.98px) {
//     .ftco-navbar-light .navbar-brand {
//         color: #fff;
//         padding-top: 1rem;
//         padding-bottom: 1rem;
//     }

//     .ftco-navbar-light .navbar-brand span {
//         color: #ffa41b;
//     }
// }

// @media (max-width: 991.98px) {
//     .ftco-navbar-light .navbar-nav {
//         padding-bottom: 20px;
//         margin-right: 0;
//     }
// }

// @media (max-width: 991.98px) {
//     .ftco-navbar-light {
//         background: #000000 !important;
//         position: relative;
//         top: 0;
//         padding-top: 0;
//         padding-bottom: 0;
//     }
// }

// @media (min-width: 992px) {
//     .ftco-navbar-light .container {
//         background: #fff;
//         border-radius: 4px;
//     }
// }

// @media (max-width: 991.98px) {
//     .ftco-navbar-light .navbar-nav>.nav-item>.nav-link {
//         padding-left: 0;
//         padding-right: 0;
//         padding-bottom: 0;
//         color: #fff;
//     }
// }

// @media (max-width: 991.98px) {
//     .ftco-navbar-light .navbar-nav>.nav-item.ftco-seperator {
//         padding-left: 0;
//         margin-left: 0;
//     }
// }

// @media (max-width: 991.98px) {
//     .ftco-navbar-light .navbar-nav>.nav-item.ftco-seperator:before {
//         display: none;
//     }
// }

// @media (max-width: 991.98px) {
//     .ftco-navbar-light .navbar-nav .nav-item.active a {
//         color: #323edd;
//     }
// }



// .ftco-navbar-light.scrolled {
//     position: fixed;
//     right: 0;
//     left: 0;
//     top: 0;
//     margin-top: -130px;
//     background: #fff !important;
//     -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
//     box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
//     padding: 0;
// }

// @media (max-width: 991.98px) {
//     .ftco-navbar-light.scrolled .nav-item .nav-link {
//         padding-left: 0 !important;
//         padding-right: 0 !important;
//         padding-bottom: 0 !important;
//     }
// }

// .ftco-navbar-light.scrolled .nav-item.active>a {
//     color: #323edd !important;
// }

// .ftco-navbar-light.scrolled .nav-item.cta>a {
//     color: #fff !important;
//     background: #323edd;
//     border: none !important;
//     padding-top: 0.5rem !important;
//     padding-bottom: .5rem !important;
//     padding-left: 20px !important;
//     padding-right: 20px !important;
//     margin-top: 6px !important;
//     -webkit-border-radius: 5px;
//     -moz-border-radius: 5px;
//     -ms-border-radius: 5px;
//     border-radius: 5px;
// }

// .ftco-navbar-light.scrolled .nav-item.cta>a span {
//     display: inline-block;
//     color: #fff !important;
// }

// .ftco-navbar-light.scrolled .nav-item.cta.cta-colored span {
//     border-color: #323edd;
// }

// @media (max-width: 991.98px) {
//     .ftco-navbar-light.scrolled .navbar-nav {
//         background: none;
//         border-radius: 0px;
//         padding-left: 1rem !important;
//         padding-right: 1rem !important;
//         margin-left: 0 !important;
//     }
// }

// .ftco-navbar-light.scrolled .navbar-toggler {
//     border: none;
//     color: rgba(0, 0, 0, 0.5) !important;
//     border-color: rgba(0, 0, 0, 0.5) !important;
//     cursor: pointer;
//     margin-right: 15px !important;
//     text-transform: uppercase;
//     font-size: 16px;
//     letter-spacing: .1em;
// }

// .ftco-navbar-light.scrolled .nav-link {
//     padding-top: 1rem !important;
//     padding-bottom: 1rem !important;
//     color: #000000 !important;
// }

// .ftco-navbar-light.scrolled .nav-link.active {
//     color: #323edd !important;
// }

// .ftco-navbar-light.scrolled.awake {
//     margin-top: 0px;
//     -webkit-transition: .3s all ease-out;
//     -o-transition: .3s all ease-out;
//     transition: .3s all ease-out;
// }

// .ftco-navbar-light.scrolled.sleep {
//     -webkit-transition: .3s all ease-out;
//     -o-transition: .3s all ease-out;
//     transition: .3s all ease-out;
// }

// .ftco-navbar-light.scrolled .navbar-brand {
//     color: #323edd;
//     padding-top: 1rem;
//     padding-bottom: 1rem;
// }

// .ftco-navbar-light.scrolled .navbar-brand span {
//     color: #ffa41b;
// }

.nav-link:hover,
.nav-link:focus {
    color: #323edd !important;
}
