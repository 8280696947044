
// .was-validated .form-control:valid, .form-control.is-valid {
//     border-color: #323edd !important;
// }

.was-validated .form-control:invalid, .form-control.is-invalid {
     border-color: #dc3545 !important;
    .contactForm .form-control:focus,
    .contactForm .form-control:active {
      border-color: #dc3545 !important; 
    }
}
.contactForm .form-control:focus,
.contactForm .form-control:active {
  border-color: #323edd ; 
}

  .phoneNumber {

    border-color: #dc3545 !important; 
  }

.tel-input{
  .form-control{
    padding: 0px !important;
    input{
      width: 100% !important;
    /* padding: 23px; */
    height: 49px !important;
    border: none;
    padding-left: 46px !important;
    }
  }
}
  

