.content {
	border: 1px solid rgb(128, 123, 123);
	padding: 0.5rem;
	border-radius: 5px;
}

.content-parent {
	height: 0px;
	overflow: hidden;
	transition: height ease 0.9s;
}

.content-show {
	height: 360px;
	max-height: 1000px;
	overflow: hidden;
	transition: height ease 0.9s;
}