.serviceIcon {
    height: 70px !important; 
    margin-bottom: 8px;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(295deg) brightness(101%) contrast(101%);
}


// .services:hover {
//     .servicesIcon {
//         filter: invert(68%) sepia(60%) saturate(1867%) hue-rotate(345deg) brightness(105%) contrast(101%) !important;
//     }
// }